import { formatDate } from '@angular/common';
const today = new Date();

export class _Subject {
  public static fromJson(json: Object): _Subject {
    return new _Subject(
      json['label'],
      json['since']
    );
  }

  constructor(
    public _id?: String,
    public label: String = '',
    public since: String = formatDate(today, 'dd/MM/yyyy', 'fr')
  ) {}
}
export class _Cours {
  public static fromJson(json: Object): _Cours {
    return new _Cours(
      json['title'],
      json['author'],
      json['createdOn'],
      json['theme'],
      json['diapos'],
      json['link']
    )
  }

  constructor(
    public _id?: String,
    public title: String = '',
    public author: String = '',
    public createdOn: Number = Date.now(),
    public theme: String = '',
    public diapos: Array<String> = [],
    public link: any = null
  ) {}
}
export class _Diapo {
  public static fromJson(json: Object): _Diapo {
    return new _Diapo(
      json['position'],
      json['title'],
      json['content'],
      json['createdOn'],
      json['author'],
      json['tags']
    )
  }

  constructor(
    public _id?: String,
    public position: Number = null,
    public title: String = '',
    public content: String = '',
    public createdOn: Number = Date.now(),
    public author: String = '',
    public tags: Array<String> = []
  ) {}
}
export class _Profil {
  public static fromJson(json: Object): _Profil {
    return new _Profil(
      json['user'],
      json['mail'],
      json['organism'],
      json['birthdate'],
      json['promotion'],
      json['background'],
      json['already_known'],
      json['level']
    );
  }
  constructor(
    public _id?: string,
    public user: string = '',
    public mail: string = '',
    public organism: string= '',
    public birthdate: string = '',
    public promotion: string = '',
    public background: string = '',
    public already_known: Array<string> = [],
    public level: Number = 0
  ) {}
}
export class _Corrige {
  public static fromJson(json: Object): _Corrige {
    return new _Corrige(
      json['title'],
      json['author'],
      json['createdOn'],
      json['theme'],
      json['diapos'],
      json['link']
    )
  }

  constructor(
    public _id?: String,
    public title: String = '',
    public author: String = '',
    public createdOn: Number = Date.now(),
    public theme: String = '',
    public diapos: Array<String> = [],
    public link: string = null
  ) {}
}

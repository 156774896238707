const today = new Date();

export class _Promotion {
  public static fromJson(json: Object): _Promotion {
    return new _Promotion(
      json['name'],
      json['organism'],
      json['lieu'],
      json['year'],
      json['month'],
      json['subject'],
      json['member'],
      json['hours'],
      json['priceHours'],
      json['cours'],
      json['corrige'],
      json['exo']
    );
  }

  constructor(
    public _id?: String,
    public name: String = '',
    public organism: String = '',
    public lieu: String = '',
    public year: Number = today.getFullYear(),
    public month: Number = today.getMonth() + 1,
    public subject:  String = '',
    public member: Array<String> = [],
    public hours: Number = 0,
    public priceHours: Number = 0,
    public cours: Array<String> = [],
    public corrige: Array<String> = [],
    public exo: Array<String> = []
  ) {}

}
